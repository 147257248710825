// Here you can add other styles

.form__row {
  margin-top: 15px;
  margin-bottom: 15px;
}

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}
// @media only screen and (min-width: 992px) {
//   .sidebar,
//   .nav {
//     width: 220px !important;
//   }

//   .main {
//     margin-left: 220px !important;
//   }
// }

.max600 {
  max-width: 600px;
}

.rt-th {
  text-align: left;
}

// Dropzone

.dropzone-wrapper {
  width: 100%;
  border: $gray-400 dashed 2px;
  @include border-radius($border-radius-lg);
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  height: 100px;
  transition: all 0.2s;
  padding: 10px;

  &:hover {
    border-color: $primary;
  }

  & > div {
    width: 100%;
    height: 100%;
    outline: none !important;
    display: flex;
    align-items: center;
  }

  &.dropzone-wrapper-lg {
    height: 200px;
  }

  &.dropzone-wrapper-sm {
    height: 150px;
  }

  input {
    width: 100%;
    height: 100%;
  }

  .dropzone-content {
    margin: 0 auto;
    color: $gray-600;
  }

  &[aria-disabled="true"] {
    border-color: $gray-300;

    .dropzone-content {
      color: $gray-400;
    }
  }
}

.list-group-item {
  padding: 0;
}

.card--starter-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  // max-width: 300px;
  &:hover {
    background-color: #f8f9fa !important;
    cursor: pointer;
  }
}

.h275 {
  height: 275px;
}

.breadcrumb {
  background-color: white;
}

ol.breadcrumb {
  margin-block-end: 0;
}

.container {
  padding: 1rem 1rem;
  max-width: 1280px;
  margin-left: 0;
  margin-right: 1rem;
}

// .tool-btn {
//   display: "inline-block";
//   /* width: 40px;
//     height: 40px; */
//   background-color: lightgray;
//   border: none; /* Remove borders */
//   color: white; /* White text */
//   padding: 6px 8px; /* Some padding */
//   /*font-size: 1vw;*/ /* Set a font size */
//   cursor: pointer; /* Mouse pointer on hover */
//   height: 35px;
// }

// .tool-btn:hover,
// .tool-btn-selected {
//   background-color: lightskyblue !important;
// }

.thumbnail {
  border: 1px solid #021a40;
  padding: 1px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.thumbnail:focus {
  border: 1px solid red;
}

.ribbon {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #D8AD3F;
  color: white;
  text-align: center;
  padding: 10px 50px 10px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 1;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.1);
}

.ribbon span {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  display: block;
}

@media only screen and (min-width: 768px) {
  .regions-document {
    order: 1;
  }

  .regions-sidebar {
    order: 2;
  }
}

@media only screen and (max-width: 767px) {
  .regions-document {
    order: 2;
  }

  .regions-sidebar {
    order: 1;
  }
}

.field-summary__field {
  &:hover {
    background-color: Gainsboro;
    cursor: pointer;
  }
}

.google-button {
  width: 222px;
  height: 40px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;

  &:focus,
  &:hover {
    box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: #e5e5e5;
    box-shadow: none;
    transition-duration: 10ms;
  }

  &:disabled {
    opacity: 0.4;
  }
}

.google-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.google-button__icon--plus {
  width: 27px;
}

.google-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", arial, sans-serif;
}

.facebook-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 16px 0 20px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", arial, sans-serif;
}

.btn--connect-to-quickbooks {
  width: 230px;
  height: 40px;
  background-size: 100% 100%;
  background-image: url("../icons/C2QB_white_btn_lg_default.png");
  &:hover {
    background-image: url("../icons/C2QB_white_btn_lg_hover.png");
  }
}

.no-transition {
  transition: none;
}

.editable-text {
  &:hover {
    cursor: pointer;
  }

  &:after {
    content: "\f044";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    margin: 0px 0px 0px 10px;
    text-decoration: none;
    
  }
}

.responsible-table {
  overflow-x: auto;
  display: block;
}

.w100 {
  width: 100px;
}
.w20 {
  width: 20px;
  display: block;
}
.w30 {
  width: 30px;
  display: block;
}
.w35 {
  width: 35px;
  display: block;
}
.w40 {
  width: 40px;
  display: block;
}
.w160 {
  width: 160px;
}

.rc-tooltip {
  z-index: 1500;
}
.rc-slider-tooltip {
  z-index: 1500;
}

.modal-xl {
  max-width: 90% !important;
}

.btn--template-filters {
  width: 200px;
}

.h200 {
  height: 120px;
}

.service-box {
  // background-color: white;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  min-height: 100px;

  &:hover {
    background-color: #f8f9fa !important;
    cursor: pointer;
  }
}

.z900 .popover {
  z-index: 900 !important;
}

.card__document-editor {
  width: 300px;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.card__onboarding-step {
  // width: 300px;
  background-color: #f8f9fa !important;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.card__onboarding-step-completed {
  opacity: 0.25 !important;
}

.card__document-editor--deleted {
  opacity: 0.2;
  border-color: "red";
  border-style: "dashed";
}

.card__document-editor--selected {
  background-color: yellow !important;
}

.h-100 {
  max-height: calc(100vh - 225px);
  overflow-y: auto;
}

.w-120 {
  width: 120px;
}

.template-modal-image {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  height: 190px;
}

h5.modal-title {
  width: 100%;
}

.app-header {
  // max-width: 1600px;
}

.w200 {
  width: 200px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.div--onboarding-banner {
  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  @media (min-width: 1280px) {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
}

// /* Hide scrollbar for Chrome, Safari and Opera */
// ::-webkit-scrollbar {
//   display: none;
// }

// /* Hide scrollbar for IE and Edge */
// * {
//   -ms-overflow-style: none;
// }

.overlay {
  background: rgba(0, 0, 0, 0.3);
  // display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99998;
}

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  width: 100%;
  height: 100%;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.z99999 {
  z-index: 99999;
}

.z1 {
  z-index: 1;
}

.canvas-mode-banner {
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: -2% auto;
  width: 98%;
  background-color: #fff;
  z-index: 99999;
}

.c-body {
  background-color: white;
}

.cursor-pointer {
  cursor: pointer;
}

.w20p {
  width: 20%;
}
.w45p {
  width: 45%;
}
table {
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
}

.td-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 0;
}

.horizontal-scroll > .row {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
.horizontal-scroll > .row > .col-md-2 {
  display: inline-block;

}

.Select-menu-outer {
  z-index: 999 !important;
}

// tree view stuff


 .tree,
 .tree-node,
 .tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

 .tree-branch-wrapper,
 .tree-node__leaf {
  outline: none;
  outline: none;
}

 .tree-node {
  cursor: pointer;
}

 .tree-node:hover {
  // background: rgba(255, 255, 255, 0.1);
  background: lightblue
}

 .tree .tree-node--focused {
  background: rgba(255, 255, 255, 0.2);
  background: lightblue;
}

 .tree .tree-node--selected {
  background: rgba(48, 107, 176);
   background: lightblue;
}

 .tree-node__branch {
  display: block;
}

 .icon {
  vertical-align: middle;
  padding-right: 5px;
}

.scrollable-horizontal {
  overflow-x: auto;
  white-space: nowrap;
}

/* Optional: if you want to ensure that the content does not wrap */
.scrollable-content {
  display: inline-block;
}


.editable-text-icon-container {
  display: flex;
  align-items: center;
  max-width: '90%'; /* Adjust based on your needs */
  white-space: nowrap;
  // background-color: #f0f0f0; /* Just for visibility */
  padding: 5px;
}

.editable-text-truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
}

.editable-text-fas {
  flex-shrink: 0;
  padding-left: 8px;
}

.billing-button {
  border-radius: 50px;
  font-weight: bold;
  padding: 10px 20px;
}

.billing-button.active {
  background-color: #007bff;
  color: white;
  border: none;
}

.billing-button.inactive {
  background-color: #f5f5f5;
  color: #007bff;
  border: 2px solid #007bff;
}

.selected-option {
  background-color: #e0f7fa;
  border-radius: 8px;
  border: 2px solid #007bff;
}

.option-container {
  padding: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s, border 0.3s;
}

.option-container:hover {
  background-color: #f1f1f1;
}

.price-details {
  font-weight: bold;
}

.savings-text {
  font-size: 0.9em;
  color: green;
}

.overlay-custom-class-name {
  z-index: 9999;
}

.ag-root {
  overflow-x: scroll !important;
  overflow-y: scroll !important;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.pdf-viewer-modal .modal-body {
  height: calc(100vh - 60px);
}
